import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import moment from 'moment'
import Helmet from 'react-helmet'

import AmpImage from '../components/amp/AmpImage'
import { Layout, RelatedListItem, SearchBox } from '../components/common'
import { MetaData } from '../components/common/meta'
import { getPublisher } from '../utils/postUtils'
import { labels } from '../utils/siteConfig'

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const Post = ({ data, location, pageContext }) => {
  const post = data.ghostPost
  const primaryTag = post.primary_tag
  let lang = 'en'
  if (post.tags.find((tag) => tag.slug === 'hash-es')) lang = 'es'

  // Related Posts
  // Remove the current article from the related posts
  let relatedPosts = pageContext.relatedPosts || []
  relatedPosts = relatedPosts.filter((relatedPost) => relatedPost.slug !== post.slug)
  const displayRelated = primaryTag && relatedPosts.length  > 0

  // Further Reading
  const furtherReading = pageContext.furtherReadingPosts || []
  const displayFurtherReading = primaryTag && furtherReading.length  > 0

  // Check for a translation of this article
  const translations = pageContext.translations || []
  const translation = translations.find((t) => t.path === `/${pageContext.slug}/` && t.altPath)

  // Pull out the source publisher
  const publisher = getPublisher(post.html)
  const logoStyle = {}
  if (publisher) logoStyle.width = `${publisher.logo.width}px`

  const publishedDate = moment(post.published_at).local('EDT').format('DD MMMM, YYYY z')
  const updatedDate = post.published_at === post.updated_at ? null : moment(post.updated_at).local('EDT').format('DD MMMM, YYYY h:mm a z')

  const UpdateButton = () => {
    return (
      <div className="updateCallout">
        If any of this content is out of date or incorrect please contact us.
        <a href="https://airtable.com/shriyme5pHTeAUlTQ" target="_blank">Suggest an update</a>
      </div>
    )
  }

  // { post.feature_image ? (
  //   <figure className="post-feature-image">
  //     <amp-img src={ post.feature_image } alt={ post.title } />
  //   </figure>
  // ) : null }

  return (
    <>
      <MetaData
        data={{ ghostPost: { ...post, publisher }}}
        location={location}
        type="article"
        hasAmp={pageContext.hasAmp}
      />
      <Helmet>
          <style amp-custom="true" type="text/css">{`${post.codeinjection_styles}`}</style>
      </Helmet>
      <Layout isAmp={pageContext.isAmp} lang={lang}>
        {publisher && (
          <div class="partner-logo" style={logoStyle}>
            <img
              src={publisher.logo.src}
              alt={publisher.name}
              height={publisher.logo.height}
              width={publisher.logo.width}
            />
          </div>
        )}

        <h1>{post.title}</h1>
        <div className="pub-dates">
          <span>Published: {publishedDate}</span>
          {updatedDate && (
            <>
              <span className="separator"> | </span>
              <span>Last updated: {updatedDate}</span>
            </>
          )}
          {translation && (
            <>
              <span className="separator"> | </span>
              <span><a href={translation.altPath}>{translation.altLang === 'es' ? 'Español' : 'English'}</a></span>
            </>
          )}
        </div>
        <article dangerouslySetInnerHTML={{ __html: post.html }} />

        <UpdateButton />

        <SearchBox isAmp={pageContext.isAmp} lang={lang} />

        {displayRelated && (
          <div class="related">
            <h2>{labels[lang].relatedContent}: {primaryTag.name}</h2>
            <ul>
              {relatedPosts.map((relatedPost) => (
                <RelatedListItem post={relatedPost} />
              ))}
            </ul>
          </div>
        )}

        {displayFurtherReading && (
          <div class="related">
            <h2>Further Reading</h2>
            <ul>
              {furtherReading.map((relatedPost) => (
                <RelatedListItem post={relatedPost} />
              ))}
            </ul>
            <p className="furtherReading">
              These stories were selected from the&nbsp;
              <a href="https://storytracker.solutionsjournalism.org/">Solutions Story Tracker</a>,
              and curated by the <a href="https://www.solutionsjournalism.org/">Solutions Journalism Network</a>, a nonprofit that promotes
              reporting on responses to social problems. SJN is responding to the COVID-19 crisis by
              sharing journalism on efforts to contain the virus, cope
              with its social and economic impacts, and help communities recover.
            </p>
          </div>
        )}

      </Layout>
    </>
  )
}

Post.propTypes = {
  data: PropTypes.shape({
    ghostPost: PropTypes.shape({
      codeinjection_styles: PropTypes.object,
      title: PropTypes.string.isRequired,
      html: PropTypes.string.isRequired,
      feature_image: PropTypes.string,
    }).isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`
