// Copyright 2018, Distributed Media Lab, Inc.

const cheerio = require('cheerio')
const { partners, siteUrl } = require('../utils/siteConfig')

const getPublisher = (html) => {
  const publishers = []
  // Pull out the source publisher
  const $ = cheerio.load(html)
  const firstEl = $('p')[0]
  const text = firstEl ? firstEl.children[0].data.toLowerCase() : ''

  if (text.startsWith('from')) {
    // Get the parts of the string
    const part = text.substring(4).trim()
    if (part.length > 0) publishers.push(part)
  }

  if (text.startsWith('from') || text.startsWith('fuente')) {
    const part = text.substring(6).trim()
    if (part.length > 0) publishers.push(part)
  }

  if (text.startsWith('by')) {
    // Get the parts of the string
    const parts = text.split(',')

    if (parts.length > 1) {
      publishers.push(parts[1].trim())
    }
  }

  const publisherKeys = publishers.map((pub) => pub.replace(/the/, '').replace(/ /g, ''))

  // = { name: publisherKeys[0], ...partnerLogos[]}
  const matchedPublishers = publisherKeys.filter((key) =>  partners[key])
    .map((key) => ({
      name: key,
      domain: partners[key].domain,
      logo: {
        src: partners[key].logo.src ? partners[key].logo.src : `${siteUrl}/partner-logos/${key}.png`,
        height: partners[key].logo.height,
        width: partners[key].logo.width
      }
    }))
  return matchedPublishers[0]
}


const postsByTag = (posts, externalLinks = []) => {
  const tagIndex = {}

  // Create an index of posts by tag
  posts.forEach(({ node }) => {
    const tags = node.tags || []
    tags.forEach((tag) => {
      if (!tagIndex[tag.slug]) tagIndex[tag.slug] = []
      tagIndex[tag.slug].push({
        slug: node.slug,
        title: node.title
      })
    })
  })

  const slugify = (name) => {
    return name.toLowerCase().replace(/ /g, '-').replace(/'/g, '')
  }

  // External links are pulled in from airtable
  // Note these columns are specific to the table itself
  externalLinks.forEach(({ node }) => {
    // Ignore if missing a headline, likely an empty row
    const link = {
      url: node.data.URL || node.data.Source_URL,
      title: node.data.Headline || node.data.Question_Page_Title
    }

    if (link.title) {
      const tags = node.data.Tags || []
      tags.forEach((tag) => {
        const slug = slugify(tag.data.Tag)
        if (!tagIndex[slug]) tagIndex[slug] = []
        tagIndex[slug].push(link)
      })
    }
  })

  return tagIndex
}

module.exports = {
  getPublisher,
  postsByTag
}