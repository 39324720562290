import React from 'react'
import PropTypes from 'prop-types'

/**
 * From: https://gist.github.com/misscs/686e4ac11e8f2f6e19a7bb3b8ddf863f
 * AMP Image component
 * Render images in AMP pages.
 * This component is for JPGs. Do not use for animated gifs or svgs.
 * <amp-img> relies on JavaScript, if the user chooses to disable scripts,
 * images won't display so we always provide a fallback.
 * Provides a fallback if images cannot load or site is offline.
 * @module Core/Image/Image
 * @param {string} src - Path to image
 * @param {string} height - Determines the aspect ratio
 * @param {string} width - Determines the aspect ratio
 * @param {string} alt - Description of the image for a11y and SEO
 * @param {string} layout - Controls how image renders on screen.
 *
 * Additionally, you can pass additional attributes through ...attrs
 * @param {array} srcset - Specify different images for varying viewport widths * and pixel densities. The w descriptor tells the browser the width of each
 * image. AMP supports srcset with the w descriptor across all browsers.
 * @param {array} sizes - Calculates the element size based on any media
 * expression. Based on the element’s calculated size, the user agent selects
 * the most relative source supplied by the srcset attribute.
 * @param {} heights - Only supported when layout=responsive. Similar to sizes,
 * but only applies height and percent values indicate the percents of elements
 * width. {@link https://www.ampproject.org/docs/guides/responsive/art_direction#heights|heights}

 */

const Image = ({
  src,
  height,
  width,
  alt,
  layout = 'responsive',
  ...attrs
}) => {
  return (
    <amp-img
      src={src}
      height={height}
      width={width}
      alt={alt}
      layout={layout}
      {...attrs}
    >
      <noscript>
        <img src={src} width={width} height={height} alt={alt} />
      </noscript>
      <div fallback="fallback">offline</div>
    </amp-img>
  )
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  layout: PropTypes.string,
}

export default Image